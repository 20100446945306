
import tratamientos from "@/core/data/tratamientos";
import { Menor } from "@/store/models";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "formularios-familia-detalle-menor-item",
  props: {
    menor: { type: Object as () => Menor, required: true },
  },
  setup(props) {
    const tratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === props.menor?.tratamiento
      );

      return result ?? tratamientos[3];
    });

    return {
      tratamiento,
      menorData: computed(() => props.menor),
    };
  },
});
