
import { AuthModule, FamiliasListadoModule } from "@/store/modules";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Evento {
  tipo: string;
  fecha: Date;
  contenido: string;
}

export default defineComponent({
  name: "formularios-familia-historico-detalle",
  components: {
    ErrorMessage,
  },

  methods: {
    formatLongDate(fecha) {
      return moment(fecha).locale("es").format("LL");
    },
    formatHour(fecha) {
      return moment(fecha).format("HH:mm:ss");
    },
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const token = route.params.id;
    const store = useStore();
    const module = getModule(FamiliasListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const error = computed(() => module.errors);

    enum HistoricoTipo {
      creacion = "CREACION",
      solicitudInicial = "SOLICITUD_INICIAL",
      aprobacion = "APROBACION",
      modificacion = "MODIFICACION",
      solicitudServicioCreacion = "SOLICITUD_SERVICIO_CREACION",
      solicitudServicioModificacion = "SOLICITUD_SERVICIO_MODIFICACION",
      notasInternas = "NOTAS_INTERNAS",
      llamada = "LLAMADA",
      email = "EMAIL",
      adjuntos = "ADJUNTOS",
    }
    const icons = {
      [HistoricoTipo.creacion]: "fas fa-plus-circle",
      [HistoricoTipo.solicitudInicial]: "fas fa-plus-circle",
      [HistoricoTipo.aprobacion]: "fas fa-check-circle",
      [HistoricoTipo.modificacion]: "fas fa-edit",
      [HistoricoTipo.solicitudServicioCreacion]: "fas fa-plus-circle",
      [HistoricoTipo.solicitudServicioModificacion]: "fas fa-edit",
      [HistoricoTipo.notasInternas]: "fas fa-sticky-note",
      [HistoricoTipo.llamada]: "fas fa-phone-alt",
      [HistoricoTipo.email]: "fas fa-envelope",
      [HistoricoTipo.adjuntos]: "fas fa-paperclip",
    };

    const textos = {
      [HistoricoTipo.creacion]: "Creación de la familia",
      [HistoricoTipo.solicitudInicial]: "Recepción de solicitud",
      [HistoricoTipo.aprobacion]: "Alta en Plan Corresponsables",
      [HistoricoTipo.modificacion]: "Modificación de datos",
      [HistoricoTipo.solicitudServicioCreacion]: "Solicitud de servicio",
      [HistoricoTipo.solicitudServicioModificacion]:
        "Cambio de estado en servicio",
      [HistoricoTipo.notasInternas]: "Nota interna",
      [HistoricoTipo.llamada]: "Llamada telefónica",
      [HistoricoTipo.email]: "Consulta por correo electrónico",
      [HistoricoTipo.adjuntos]: "Adjuntos",
    };

    const addLog = ref<boolean>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const schema = Yup.object().shape({
      tipo: Yup.string().required().label("Tipo"),
      fecha: Yup.date().required().label("Fecha"),
      contenido: Yup.string().required().label("Contenido"),
    });

    const { handleSubmit, resetForm, meta } = useForm<Evento>({
      validationSchema: schema,
    });

    const { value: tipo } = useField("tipo");
    const { value: fecha } = useField("fecha");
    const { value: contenido } = useField("contenido");

    const onSubmitNewLog = handleSubmit((values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      module
        .familiaHistorico({ comarcaId, id: token as string, data: values })
        .then(async () => {
          Swal.fire({
            text: "Se ha añadido el registro al log.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          await loadFamiliaHistorico();
          resetForm({
            values: {
              tipo: "NOTAS_INTERNAS",
              fecha: new Date(),
              contenido: "",
            },
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then login failed
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "Se produjo un error. Por favor, inténtelo de nuevo en unos minutos.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    });

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaDetalle = ref<boolean>(false);
    onBeforeMount(async () => {
      await loadFamiliaHistorico();
      resetForm({
        values: {
          tipo: "NOTAS_INTERNAS",
          fecha: new Date(),
          contenido: "",
        },
      });
    });

    const toggleLog = function () {
      addLog.value = !addLog.value;
    };

    const loadFamiliaHistorico = async function () {
      cargaDetalle.value = true;
      try {
        await module.fetchFamiliaHistorico({
          comarcaId: comarcaId,
          id: token as string,
        });
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaHistorico.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    };

    const datosHistorico = computed(() => module.eventosListado);

    watch(datosHistorico, () => {
      if (!datosHistorico.value) return;
    });

    return {
      tipo,
      fecha,
      contenido,
      onSubmitNewLog,
      datosHistorico,
      textos,
      icons,
      meta,
      HistoricoTipo,
      submitButton,
      moment,
      toggleLog,
      addLog,
    };
  },
});
