import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-6" }
const _hoisted_2 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_3 = { class: "col-lg-8 fv-row" }
const _hoisted_4 = { class: "row mb-6" }
const _hoisted_5 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_6 = { class: "col-lg-8 fv-row" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-6 fv-row fv-plugins-icon-container" }
const _hoisted_9 = { class: "col-lg-6 fv-row fv-plugins-icon-container" }
const _hoisted_10 = { class: "row mb-6" }
const _hoisted_11 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_12 = { class: "col-lg-8 fv-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("formularios.familiaDetalle.tratamiento.label")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_input, {
          disabled: "",
          type: "text",
          name: "tratamiento",
          placeholder: " ",
          modelValue: _ctx.tratamiento.text,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tratamiento.text) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("formularios.familiaDetalle.nombre.label")), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_input, {
              disabled: "",
              type: "text",
              placeholder: " ",
              modelValue: _ctx.menorData.nombre,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menorData.nombre) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_input, {
              disabled: "",
              type: "text",
              placeholder: " ",
              modelValue: _ctx.menorData.apellidos,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menorData.apellidos) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("formularios.familiaDetalle.menor.fechaNacimiento.label")), 1),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_el_date_picker, {
          disabled: "",
          format: "DD/MM/YYYY",
          modelValue: _ctx.menorData.fechaNacimiento,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.menorData.fechaNacimiento) = $event)),
          type: "date",
          class: "w-100"
        }, null, 8, ["modelValue"])
      ])
    ])
  ], 64))
}